<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Timeline from "@/components/dashboard/journeys/timeline"
import helpers from '../../../helpers'

import {
  analyticsMethods,
  journeyMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Journeys Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Timeline
  },
  data() {
    return {
      title: this.$t('journeys_analyze.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,

      executions: '-',
      ended: '-',
      canceled: '-',
      notifications:'-',
      conversion_rate: '-',
      revenue: '-',
      journeysAnalytics: [],
      periodLength: 0,
      journeyId: null,
      filters:[],

      previousAnalytics: [],
      previous_executions: '-',
      previous_revenue: '-',
      previous_ended: '-',
      previous_canceled: '-',
      previous_conversion_rate: '-',
      previous_notifications: '-'
      
    };
  },
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 

    this.journeyId = this.$route.query?.journey;

    if(this.journeyId){
      this.getJourney(this.journeyId).then((journey)=>{
        if(journey?.data){
          this.filters.push({
            key: journey.data._id,
            value: journey.data._id,
            badge: `Journey: ${journey.data.name}`
          })
        }
        const nextDay = new Date(this.dateFilter[1]);
        nextDay.setDate(this.dateFilter[1].getDate() + 1)

        let query = `project=${localStorage.getItem('current_project')}`
        query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

        this.applyFilter(query)
      })
    }

  },
  methods:{
    ...analyticsMethods,
    ...journeyMethods,
    ...helpers,

    onComparePreviousPeriod(){
      if(this.filters.filter(f=>f.key=='compare').length==0){
        this.filters.push({
          badge: this.$t('common.compare_badge'),
          key: 'compare'
        })
        this.applyFilter(this.query);
      }
      this.$refs.actionsDropdown.hide();
    },

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key!='compare'){
            q+= `&key=${f.key}&value=${f.value}`
          }
        });
      }

      return q;
    },

    applyFilter(query){
      
      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }
      this.query = query;

      const loader = this.$loading.show();

      this.getJourneysReports(query).then((res)=>{
        this.journeysAnalytics = res.data;

        this.executions = res.data.length > 0 ? this.getSummaryValueByKey('executions', this.journeysAnalytics): 0; 
        this.ended = res.data.length > 0 ? this.getSummaryValueByKey('ended', this.journeysAnalytics): 0;
        this.canceled = res.data.length > 0 ? this.getSummaryValueByKey('cancel', this.journeysAnalytics): 0;
        this.notifications = res.data.length > 0 ? this.getSummaryValueByKey('notifications', this.journeysAnalytics): 0; 
        this.revenue = res.data.length > 0 ? this.getSummaryValueByKey('revenue', this.journeysAnalytics): 0; 

        const period = this.journeysAnalytics.filter(x => x.label === "conversion_rate");
        period.sort((a,b)=>new Date(a.date).getTime()- new Date(b.date).getTime());

        let cr_periodLength = 0;
        if(period.length > 1){
          const timeDifference = new Date(period[period.length-1].date).getTime() - new Date(period[0].date).getTime();
          cr_periodLength=  timeDifference / (1000 * 3600 * 24) + 1;
        } 

        this.conversion_rate = cr_periodLength == 0 ? res.data.length > 0 ? this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.journeysAnalytics)): 0 : this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.journeysAnalytics)/ cr_periodLength); 

        if(this.filters.filter(f=>f.key=='compare').length>0){
         this.getPreviousIndicators();
        }

      }).finally(()=>{
        loader.hide();
      })
    },
    onRemoveFilter(filter){
      this.filters= this.filters.filter(f=>f.key!=filter.key);

      if(filter.key== 'compare'){
        this.previousSearchAnalytics = [];
      }

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
    },

    getSummaryValueByKey(key, data) {
      if(data.length == 0){
        return 0;
      }else{
        return data.filter(x => x.label === key)
        .reduce((sum, x) => sum + parseFloat(x.value, 10), 0);
      }
    },
    getPreviousIndicators (){

      const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
      const nextDay = new Date(dates.previousDateTo);
      nextDay.setDate(dates.previousDateTo.getDate() + 1)

      this.previousDates = dates;

      let queryPrevious = `project=${localStorage.getItem('current_project')}`
      queryPrevious+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  

      queryPrevious+= this.getCustomFilterQuery();

      this.getJourneysReports(queryPrevious).then((res)=>{

        this.previousAnalytics = res.data;
        
        const prev_executions = res.data.length > 0 ? this.getSummaryValueByKey('executions', this.previousAnalytics): 0;
        this.previous_executions =  this.calculatePercentageChange(this.executions, prev_executions) ;

        const prev_ended = res.data.length > 0 ? this.getSummaryValueByKey('ended', this.previousAnalytics): 0;
        this.previous_ended = this.calculatePercentageChange(this.ended, prev_ended) ;

        const prev_canceled = res.data.length > 0 ? this.getSummaryValueByKey('cancel', this.previousAnalytics): 0;
        this.previous_canceled = this.calculatePercentageChange(this.canceled, prev_canceled) ;

        const prev_notifications = res.data.length > 0 ? this.getSummaryValueByKey('notifications', this.previousAnalytics): 0; 
        this.previous_notifications = this.calculatePercentageChange(this.notifications, prev_notifications) ;

        const prev_revenue = res.data.length > 0 ? this.getSummaryValueByKey('revenue', this.previousAnalytics): 0; 
        this.previous_revenue = this.calculatePercentageChange(this.revenue, prev_revenue) ;

        const period = this.previousAnalytics.filter(x => x.label === "conversion_rate");
        period.sort((a,b)=>new Date(a.date).getTime()- new Date(b.date).getTime());

        let cr_periodLength = 0;
        if(period.length > 1){
          const timeDifference = new Date(period[period.length-1].date).getTime() - new Date(period[0].date).getTime();
          cr_periodLength=  timeDifference / (1000 * 3600 * 24) + 1;
        } 

        const prev_cr = cr_periodLength == 0 ? res.data.length > 0 ? this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.previousAnalytics)): 0 : this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.previousAnalytics)/ cr_periodLength); 
        this.previous_conversion_rate = this.calculatePercentageChange(this.conversion_rate, prev_cr) ;
      });
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          const timeDifference = newVal[1].getTime() - newVal[0].getTime();
          this.periodLength =  Math.trunc(timeDifference / (1000 * 3600 * 24) + 1);
          
          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{ $t('journeys_analyze.title') }}</h5>
            <p class="text-muted mb-0">{{ $t('journeys_analyze.subtitle') }}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/analytics/journeys" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-50">
            <div class="row align-items-center justify-content-end">
              <div class="col-auto ps-0 w-50">
                <div class="flex">
                    <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
                      :format="'DD.MM.YYYY'" :clearable="false"
                      :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                    </date-picker>
                </div>
              </div>
              <div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-if="isLocal">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onComparePreviousPeriod">
                      <i class="mdi mdi-compare"></i>
                      {{ $t('common.compare') }}
                    </a>
                    <div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1 mt-2 mb-0" v-for="f in filters" :key="f.key">
            <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.key!= 'compare' ? f.badge : `${f.badge} (${periodLength} ${periodLength>1 ? $t('common.days_badge') : $t('common.day_badge')})` }}</span>
                <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
            </div>
          </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('journeys_analyze.executions')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('journeys_analyze.executions_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="executions">{{ parseCountReduced(executions)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_executions === 0,
                        'badge-soft-danger': previous_executions < 0,
                        'badge-soft-success': previous_executions > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_executions > 0 ? '+'  : '' } ${ previous_executions}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('journeys_analyze.ended')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('journeys_analyze.ended_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="ended">{{parseCountReduced(ended)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_ended === 0,
                        'badge-soft-danger': previous_ended < 0,
                        'badge-soft-success': previous_ended > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_ended > 0 ? '+'  : '' } ${ previous_ended}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('journeys_analyze.canceled')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('journeys_analyze.canceled_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="canceled">{{ parseCountReduced(canceled)}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_canceled === 0,
                        'badge-soft-danger': previous_canceled < 0,
                        'badge-soft-success': previous_canceled > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_canceled > 0 ? '+'  : '' } ${ previous_canceled}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('journeys_analyze.notifications')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('journeys_analyze.notifications_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="notifications">{{ parseCountReduced(notifications) }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_notifications === 0,
                        'badge-soft-danger': previous_notifications < 0,
                        'badge-soft-success': previous_notifications > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_notifications > 0 ? '+'  : '' } ${ previous_notifications}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('journeys_analyze.conversion_rate')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('journeys_analyze.conversion_rate_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{`${conversion_rate}%`}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_conversion_rate === 0,
                        'badge-soft-danger': previous_conversion_rate < 0,
                        'badge-soft-success': previous_conversion_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_conversion_rate > 0 ? '+'  : '' } ${ previous_conversion_rate}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('journeys_analyze.revenue')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('journeys_analyze.revenue_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(revenue)}`">${{parseMoneyReducedWithLocale(revenue) }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_revenue === 0,
                        'badge-soft-danger': previous_revenue < 0,
                        'badge-soft-success': previous_revenue > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_revenue > 0 ? '+'  : '' } ${ previous_revenue}%` }}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <Timeline :query="query" :trendings="journeysAnalytics" :compare="filters.filter(f=>f.key=='compare').length > 0" :oldTrendings="previousAnalytics" :periodLength="periodLength"/>
        </div>
      </div>
    </div>
</Layout>
</template>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}

.dropdown-toggle {
  padding-top: 5px !important;
}
</style>
