<script>
import Multiselect from "vue-multiselect";
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      
        series: [],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            sparkline: {
                enabled: false,
              },
            toolbar: {
              show: false,
            },
          },
          colors: ["#5e40bf", "#f1b44c"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
            dashArray: [0, 5]
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
          yaxis:{
            labels: {
              show:true
            }
          }          
        },
      
      selection: "all",
      values: [
        {id: 'executions', label: this.$t('journeys_analyze.executions')},
        {id: 'ended', label: this.$t('journeys_analyze.ended')},
        {id: 'canceled', label: this.$t('journeys_analyze.canceled')},
        {id: 'notifications', label: this.$t('journeys_analyze.notifications')},
        {id: 'conversion_rate', label: this.$t('journeys_analyze.conversion_rate')},
        {id: 'revenue', label: this.$t('journeys_analyze.revenue')},
      ],
      auxValue: {id: 'executions', label: this.$t('journeys_analyze.executions')},
      groupButtonSelected: 'day',
      weeklyTendings: [],
      monthlyTendings:[],
      
      monthlyPreviousTendings: [],
      weeklyPreviousTendings: [],
    };
  },
  components:{Multiselect},
  props: {
    query : String,
    trendings: Array,
    compare: Boolean,
    oldTrendings: Array,
    periodLength: Number
  },
  mounted(){
    const self = this;

    this.chartoptions.xaxis = {
        labels: {
          formatter: function (valor) {
            return self.parseDateWithLocale(new Date(valor),self.getDateConfigByOptions());
          }
        }
      }
      this.chartoptions.yaxis = {
        labels: {
          formatter: function (valor) {
            if(self.auxValue.id =='revenue' || self.auxValue.id =='revenue'){
              return `$${self.parseMoneyWithLocale(valor)}`;
            }
            else if(self.auxValue.id =='conversion_rate'){
              return `${self.parsePercentage(valor)}%`;
            }else{
              return valor;
            }
            
          }
        }
      }
      this.$refs.TrendsChart.updateOptions(this.chartoptions);
  },
  computed: {
    getSubtitleText(){
      switch(this.auxValue.id){
        case 'executions':
          return this.$t('journeys_analyze.executions_subtitle')
        case 'canceled':
          return this.$t('journeys_analyze.canceled_subtitle')
        case 'ended':
          return this.$t('journeys_analyze.ended_subtitle')
        case 'notifications':
          return this.$t('journeys_analyze.notifications_subtitle')
        case 'revenue':
          return this.$t('journeys_analyze.revenue_subtitle')
        case 'conversion_rate':
          return this.$t('journeys_analyze.conversion_rate_subtitle')
      }
      
      return ''
    }
  },
  methods: {
    ...analyticsMethods,
    ...helpers,
    getDateConfigByOptions(){
      if(this.groupButtonSelected == 'day'){
        return { month: 'short', day: 'numeric', timeZone: 'UTC'}
      }
      else if(this.groupButtonSelected == 'week'){
        return {month: 'long', day: 'numeric', timeZone: 'UTC'}
      }else{
        return {month: 'long', timeZone: 'UTC'}
      }
    },
    
    selectButtonFilter(day){
      this.groupButtonSelected = day;
      this.loadNewData();
    },
    onValueChanged(){
      this.loadNewData();
    },

    aggregateWeekly(data) {
      let weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;
        
        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            executions: 0,
            ended: 0,
            cancel:0,
            notifications:0,
            conversion_rate:0,
            revenue: 0,
            cr_count:0
          };
        }

        weeklyData[weekYear][item.label] += parseFloat(item.value);
        
        if(item.label=='conversion_rate'){
          weeklyData[weekYear]['cr_count']++;
        }
        
      });

      weeklyData = Object.values(weeklyData);
      weeklyData.forEach(m=>{
        Object.keys(m).forEach(k=> {
          this.weeklyTendings.push({
          date: m.date,
          label: k,
          value: m[k]
        })
        })
      });
    },
    aggregatePreviousWeekly(data) {
      let weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;

        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            executions: 0,
            ended: 0,
            cancel:0,
            notifications:0,
            conversion_rate:0,
            revenue: 0,
            cr_count:0
          };
        }

        weeklyData[weekYear][item.label] += parseFloat(item.value);
        
        if(item.label=='conversion_rate'){
          weeklyData[weekYear]['cr_count']++;
        }
      });
      weeklyData = Object.values(weeklyData);
      weeklyData.forEach(m=>{
        Object.keys(m).forEach(k=> {
          this.weeklyPreviousTendings.push({
          date: m.date,
          label: k,
          value: m[k]
        })
        })
      });
    },
    getWeekNumber(date) {
      const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getUTCDay() + 1) / 7);
    },
    getStartOfWeek(date) {
      const startOfWeek = new Date(date);
      const dayOfWeek = startOfWeek.getUTCDay();
      const diff = startOfWeek.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); 
      startOfWeek.setUTCDate(diff);
      return startOfWeek;
    },
    aggregateMonthly(data) {
      let monthlyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = {
            date: monthYear,
            executions: 0,
            ended: 0,
            cancel:0,
            notifications:0,
            conversion_rate:0,
            revenue: 0,
            cr_count:0
          };
        }

        monthlyData[monthYear][item.label] += parseFloat(item.value);
        
        if(item.label=='conversion_rate'){
          monthlyData[monthYear]['cr_count']++;
        }
      });
      
      
      monthlyData = Object.values(monthlyData);
      monthlyData.forEach(m=>{
        Object.keys(m).forEach(k=> {
          this.monthlyTendings.push({
          date: m.date,
          label: k,
          value: m[k]
        })
        })
      });
    },
    aggregatePreviousMonthly(data) {
      let monthlyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = {
            date: monthYear,
            executions: 0,
            ended: 0,
            cancel:0,
            notifications:0,
            conversion_rate:0,
            revenue: 0,
            cr_count:0
          };
        }

        monthlyData[monthYear][item.label] += parseFloat(item.value);
        
        if(item.label=='conversion_rate'){
          monthlyData[monthYear]['cr_count']++;
        }
      });

      monthlyData = Object.values(monthlyData);
      monthlyData.forEach(m=>{
        Object.keys(m).forEach(k=> {
          this.monthlyPreviousTendings.push({
          date: m.date,
          label: k,
          value: m[k]
        })
        })
      });
    },

    getCrCount(date,trends, value){
      if(trends.length == 0 )
        return value;

      const cr_count = trends.filter(t=>t.label == 'cr_count' && t.date==date);
      if(cr_count.length==0) return value;

      return value/cr_count[0].value;
    },
    loadNewData(){
      const loader = this.$loading.show();

      let trends = [];
      let oldTrends = [];
      let length = 0;
      
      if(this.groupButtonSelected == 'day'){
        trends = this.trendings;
        oldTrends = this.oldTrendings;
        length = this.periodLength;
        
      }else if(this.groupButtonSelected == 'week'){
        trends = this.weeklyTendings;
        oldTrends = this.weeklyPreviousTendings;

      }else if(this.groupButtonSelected == 'month'){
        trends = this.monthlyTendings;
        oldTrends = this.monthlyPreviousTendings;
      }
      
      if(!this.compare){
        this.series= [{
          name: this.auxValue.label,
          data:[]
        }]
      }else{
        this.series= [
          {
            name: this.auxValue.label,
            data: [],
          },
          {
            name: `${this.$t('common.previous_period')} (${this.periodLength} ${this.periodLength>1 ? this.$t('common.days_badge') : this.$t('common.day_badge')})`,
            data: [],
          },
        ]
      }

      switch(this.auxValue.id){
        case 'executions':
          if(this.trendings){
            this.series[0].data = Array.from(trends.filter(x => x.label === 'executions').map(x => [new Date(x.date).getTime(), parseInt(x.value, 10)]));
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends.filter(x => x.label === 'executions'), x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), parseInt(x.value, 10)];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'ended': 
          if(this.trendings){
            this.series[0].data = Array.from(trends.filter(x => x.label === "ended").map(x => [new Date(x.date).getTime(), parseInt(x.value, 10)]));
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends.filter(x => x.label === "ended"), x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), parseInt(x.value, 10)];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }

          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'canceled': 
          if(this.trendings){
            this.series[0].data = Array.from(trends.filter(x => x.label === "cancel").map(x => [new Date(x.date).getTime(), parseInt(x.value, 10)]));
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends.filter(x => x.label === "cancel"), x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), parseInt(x.value, 10)];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
          case 'notifications': 
          if(this.trendings){
            this.series[0].data = Array.from(trends.filter(x => x.label === "notifications").map(x => [new Date(x.date).getTime(), parseInt(x.value, 10)]));
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends.filter(x => x.label === "notifications"), x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), parseInt(x.value, 10)];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'conversion_rate': 
          if(this.trendings){

            if(this.groupButtonSelected=='day'){
              this.series[0].data = Array.from(trends.filter(x => x.label === "conversion_rate").map(x => [new Date(x.date).getTime(), parseFloat(x.value, 10)]));
            }else{
              this.series[0].data = Array.from(trends.filter(x => x.label === "conversion_rate").map(x => [new Date(x.date).getTime(), this.getCrCount(x.date, trends, x.value)]));
            }
            this.series[0].data.sort((a, b) => a[0] - b[0]);
            
            if(this.compare && this.oldTrendings){
              if(this.groupButtonSelected=='day'){
                this.series[1].data = Array.from(oldTrends.filter(x => x.label === "conversion_rate"), x => {
                  let date = new Date(x.date);
                  date.setDate(date.getDate() + length); 
                  return [date.getTime(), parseFloat(x.value, 10)];
                });
              }else{
                this.series[1].data = Array.from(oldTrends.filter(x => x.label === "conversion_rate"), x => {
                  let date = new Date(x.date);
                  date.setDate(date.getDate() + length); 
                  return [date.getTime(), this.getCrCount(x.date, oldTrends, x.value)];
                });
              }
              
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
            
          }
          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        case 'revenue': 
          if(this.trendings){
            this.series[0].data = Array.from(trends.filter(x => x.label === "revenue").map(x => [new Date(x.date).getTime(), parseFloat(x.value, 10)]));
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            if(this.compare && this.oldTrendings){
              this.series[1].data = Array.from(oldTrends.filter(x => x.label === "revenue"), x => {
                let date = new Date(x.date);
                date.setDate(date.getDate() + length); 
                return [date.getTime(), parseFloat(x.value)];
              });
              this.series[1].data.sort((a, b) => a[0] - b[0]);
            }
          }

          this.$refs.TrendsChart?.updateSeries(this.series,true);
          loader.hide();
          break;
        }
    },
  },
  watch:{
    trendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyTendings = [];
            this.monthlyTendings = [];
            this.aggregateMonthly(newVal);
            this.aggregateWeekly(newVal);
            this.loadNewData();
          }
        }
    },
    oldTrendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyPreviousTendings = [];
            this.monthlyPreviousTendings = [];
            this.aggregatePreviousMonthly(newVal);
            this.aggregatePreviousWeekly(newVal);
            this.loadNewData();
          }
        }
    }
  }
};
</script>

<template>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-column">
        <h5 class="card-title me-2">Trendings</h5>
        <p class="small mb-0">{{getSubtitleText}}</p>
      </div>
      
      <div class="ms-auto d-inline-flex">
        <multiselect 
            :options="values"
            :placeholder="''"
            track-by="id" 
            label="label"
            :show-labels="false"
            :allow-empty="false"
            :multiple="false"
            v-model="auxValue"
            @input="onValueChanged()"
            style="width: 300px;"
            class="me-2"
            />
        <div class="btn-group btn-group-sm pl-2 w-70" role="group" style="width: 70%">
          <button type="button" :class="groupButtonSelected =='day' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('day')">{{$t('common.day')}}</button>
          <button type="button" :class="groupButtonSelected =='week' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('week')">{{$t('common.week')}}</button>
          <button type="button" :class="groupButtonSelected =='month' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('month')">{{$t('common.month')}}</button>
        </div>
      </div>
    </div>

    <hr class="mb-2" />

    <apexchart
      ref="TrendsChart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :options="chartoptions"
      :series="series.length > 0 ? series : []"
    ></apexchart>
  </div>
</template>
